<template>
  <div class="wrap" id="listDBL">
    <div class="header">
      <h2 class="st_title">
        <img src="@/assets/占星小鋪/friend.svg" alt="" />
        雙人合盤
      </h2>
      <div class="filterBar">
        <label>
          <img class="serachIcon" src="@/assets/占星小鋪/search.svg" alt="" />
          <input
            type="text"
            placeholder="搜尋好友名稱"
            v-model="searchKey"
            @keyup.enter="searchHandler"
          />
        </label>
        <button class="filter_btn" @click="searchHandler">搜尋</button>
        <div class="addSynastrybtn" @click="toAddDBL()">
          <img src="@/assets/雙人合盤/addDBL.svg" alt="" />
        </div>
      </div>
    </div>
    <div class="main">
      <!-- <div class="main__tab">
        <div
          class="main__tab__item"
          :class="{ active: tabActived == 'all' }"
          @click="tabActived = 'all'"
        >
          全部
        </div>
        <div
          class="main__tab__item"
          :class="{ active: tabActived == 'couple' }"
          @click="tabActived = 'couple'"
        >
          感情<span>合盤</span>
        </div>
        <div
          class="main__tab__item"
          :class="{ active: tabActived == 'parentChild' }"
          @click="tabActived = 'parentChild'"
        >
          親子<span>合盤</span>
        </div>
        <div
          class="main__tab__item"
          :class="{ active: tabActived == 'workplace' }"
          @click="tabActived = 'workplace'"
        >
          職場<span>合盤</span>
        </div>
      </div> -->
      <div class="main__list">
        <div
          class="main__list__item"
          v-for="(item, index) in listSearchFiltered"
          :key="index"
        >
          <!-- 卡片角色資料 -->
          <div
            class="card__role"
            :class="{ expand: openInfoMap[item.SynastryId] }"
            @click="
              openInfoMap[item.SynastryId] = openInfoMap[item.SynastryId]
                ? false
                : true
            "
          >
            <div class="card__role__item">
              <div class="card__role__item__pic">
                <img
                  :src="getProfilePhotoSrc(item.Astrolabes[0].Constellation)"
                  alt=""
                />
              </div>
              <div class="card__role__item__name">
                {{ item.Astrolabes[0].Name }}
              </div>

              <div class="card__role__item__expand">
                <div class="card__role__item__expand__local">
                  {{ item.Astrolabes[0].BirthPlace }},
                </div>
                <div class="card__role__item__expand__birthday">
                  {{ item.Astrolabes[0].BirthTime }}
                </div>
              </div>
            </div>
            <div class="card__role__div">
              <div class="card__role__div__icon">
                <img src="@/assets/雙人合盤/好友分隔.svg" alt="" />
              </div>
              <div style="height: 36px"></div>
            </div>
            <div class="card__role__item">
              <div class="card__role__item__pic">
                <img
                  :src="getProfilePhotoSrc(item.Astrolabes[1].Constellation)"
                  alt=""
                />
              </div>
              <div class="card__role__item__name">
                {{ item.Astrolabes[1].Name }}
              </div>
              <div class="card__role__item__expand">
                <div class="card__role__item__expand__local">
                  {{ item.Astrolabes[1].BirthPlace }},
                </div>
                <div class="card__role__item__expand__birthday">
                  {{ item.Astrolabes[1].BirthTime }}
                </div>
              </div>
            </div>
            <div class="card__role__openBtn">
              <img
                src="@/assets/icon_downward.svg"
                :style="
                  openInfoMap[item.SynastryId] ? 'transform:rotate(180deg)' : ''
                "
              />
            </div>
          </div>
          <!-- 卡片角色資料(展開) -->

          <!-- 卡片關係 -->
          <div class="card__relationship">
            <div
              class="card__relationship__item mt-3"
              v-for="relationship in item.Relationships"
              :key="relationship"
              v-show="tabActived == 'all' ? true : isShow(relationship)"
            >
              {{ getRelationshipCN(relationship) }}
            </div>
          </div>

          <!-- 卡片右上角選擇按鈕 -->
          <div class="card__link">
            <img src="@/assets/月亮日記/icon-ok.svg" @click="toDBL(item)" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchKey: "",
      tabActived: "all",
      listSearch: [],
      openInfoMap: {},
      //列表
      ListDBL: [
        {
          SynastryId: 1,
          Astrolabes: [
            {
              Constellation: "Aries",
              Name: "John",
              BirthPlace: "Taiwan",
              BirthTime: "1995-01-01 12:00:00",
            },
            {
              Constellation: "Aries",
              Name: "Amy",
              BirthPlace: "Taiwan",
              BirthTime: "1996-01-01 12:00:00",
            },
          ],
        },
      ],
    };
  },
  computed: {
    listSearchFiltered() {
      return this.listSearch.filter((item) => {
        switch (this.tabActived) {
          case "all":
            return true; // 目前只剩下all
          case "couple":
            return item.Relationships.includes("Couple");
          case "parentChild":
            return item.Relationships.includes("Parent-child");
          case "workplace":
            return item.Relationships.includes("Workplace");
        }
      });
    },
  },
  async created() {
    if (!this.ListDBL || !this.ListDBL.length > 0) {
      this.$router.replace("/Synastry");
    }
    await this.init();

    this.listSearch = this.ListDBL;
    this.openInfoMap = this.ListDBL.reduce((acc, item) => {
      acc[item.SynastryId] = false;
      return acc;
    }, {});
  },
  methods: {
    async init() {
      let res = await this.$API.GET_SynastrysList();
      console.log(res);
      this.ListDBL = res.Data || [];
    },
    toAddDBL() {
      this.$router.push("/Synastry");
    },
    searchHandler() {
      this.listSearch = this.ListDBL.filter((item) => {
        let Astrolabes = item.Astrolabes;
        return (
          Astrolabes[0].Name.toLowerCase().includes(searchKeyLower) ||
          Astrolabes[1].Name.toLowerCase().includes(searchKeyLower)
        );
      });
    },
    isShow(relationship) {
      switch (this.tabActived) {
        case "couple":
          return relationship == "Couple";
        case "parentChild":
          return relationship == '"Parent-child"';
        case "workplace":
          return relationship == "Workplace";
      }
    },
    getProfilePhotoSrc(constellation) {
      let obj = this.$Astro.find((item) => item.en == constellation);
      return obj ? obj.img : "";
    },
    getRelationshipCN(relationship) {
      switch (relationship) {
        case "Couple":
          return "感情";
        case "Parent-child":
          return "親子";
        case "Workplace":
          return "職場";
      }
    },
    toDBL(item) {
      console.log(item);
      this.$router.push({
        name: "SynastryMain",
        params: {
          SynastryId: item.MemberSynastryId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/_mixin.scss";
@import "@/scss/toast.scss";
@import "@/scss/dialog.scss";
.wrap#listDBL {
  padding-top: 62px;
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  min-height: 100vh;
  letter-spacing: 0.08em;
  @include pad {
    padding: 20px 23px 0;
  }
  .header {
    display: flex;
    align-items: center;
    @include pad {
      flex-direction: column;
    }
    h2.st_title {
      font-weight: 500;
      font-size: 40px;
      color: $theme-color-2;
      display: flex;
      align-items: center;
      margin-right: auto;
      line-height: 1;
      @include pad {
        display: none;
      }
      img {
        width: 54px;
        margin-right: 16px;
        @include pad {
          width: 30px;
          margin-right: 5px;
        }
      }
    }

    .filterBar {
      margin: 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      @include pad {
        margin-top: 24px;
      }
      input,
      input:focus {
        width: 300px;
        height: 36px;
        background: #f0f2f4;
        box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 25px;
        outline: none;
        padding: 8px 46px;
        @include pad {
          max-width: 179px;
          height: 36px;
        }
      }
      input::placeholder {
        font-size: 14px;
        line-height: 20px;
        color: #d9d9d9;
      }
      label {
        position: relative;
        .serachIcon {
          position: absolute;
          left: 20px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
      .filter_btn {
        @include btn-default(112px, 36px);
        border-radius: 100px;
        margin-left: 16px;
        @include pad {
          @include btn-default(72px, 36px);
        }
      }
      .addSynastrybtn {
        @include btn(40px, 40px, #ab956c, #fff, 12px);
        border-radius: 50%;
        margin-left: 30px;
        @include pad {
          margin-left: 20px;
        }
        img {
          width: 80%;
        }
      }
    }
  }
  .main {
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 78px;
    width: 100%;
    @include pad {
      margin-left: 0;
      margin-right: 0;
      margin-top: 46px;
    }
    &__tab {
      display: flex;
      padding-bottom: 12px;
      width: 100%;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: #f0f2f4;
        box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
        border-radius: 2px;
      }
      &__item {
        width: 4.5em;
        text-align: center;
        font-size: 24px;
        line-height: 36px;
        letter-spacing: 0.04em;
        color: $secondary-color-3;
        margin-right: 92px;
        position: relative;
        cursor: pointer;
        @include pad {
          font-size: 18px;
          line-height: 26px;
          margin-right: 36px;
          padding: 0 8px;
          &:last-of-type {
            margin-right: 0;
          }
          span {
            display: none;
          }
        }
        &.active {
          color: $theme-color-1;
        }
        &.active:after {
          content: "";
          position: absolute;
          bottom: -12px;
          left: 0;
          width: 100%;
          height: 4px;
          background: $theme-color-1;
          border-radius: 30px;
          z-index: 2;
        }
      }
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 48px;
      @include pad {
        justify-content: center;
        gap: 32px;
      }
      &__item {
        width: 500px;
        padding: 32px 104px 28px;
        background: #f0f2f4;
        border-radius: 25px 50px;
        box-shadow: -4px -4px 16px #ffffff, -4px -4px 10px #ffffff,
          4px 4px 25px rgba(0, 0, 0, 0.25);
        position: relative;
        @include mobile {
          width: 100%;
          padding: 39px 63px 24px;
          border-radius: 12px 32px;
        }
      }
    }
  }
}
.card__role {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  &.expand {
    padding-bottom: 34px;
    @include mobile {
      padding-bottom: 38px;
    }
    .card__role__item__expand {
      display: block;
    }
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    position: relative;
    @include mobile {
      width: 60px;
    }
    &__pic {
      width: 80px;
      height: 80px;
      background: #f0f2f4;
      box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
        2px 2px 6px rgba(0, 0, 0, 0.25);
      border-radius: 50%;
      @include center;
      @include mobile {
        width: 60px;
        height: 60px;
      }
      img {
        width: 90%;
        height: 90%;
        object-fit: cover;
      }
    }
    &__name {
      text-align: center;
      height: 36px;
      line-height: 36px;
      font-size: 18px;
      letter-spacing: 0.1em;
      color: #767676;
      width: 5em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-top: 4px;
      @include mobile {
        width: 4em;
        height: 22px;
        font-size: 15px;
        line-height: 22px;
      }
    }
    &__expand {
      display: none;
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      &__local {
        font-size: 12px;
        line-height: 17px;
        color: #9b9b9b;
        margin-bottom: 2px;
      }
      &__birthday {
        font-size: 12px;
        line-height: 17px;
        color: #9b9b9b;
        white-space: nowrap;
      }
    }
  }
  &__div {
    width: 52px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include mobile {
      width: 44px;
    }
    &__icon {
      height: 52px;
      img {
        height: 100%;
      }
    }
  }
  &__openBtn {
    position: absolute;
    right: -34px;
    top: 92px;
    width: 22px;
    height: 22px;
    cursor: pointer;
    @include mobile {
      right: -22px;
      top: 69px;
      width: 18px;
      height: 18px;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.3s;
    }
  }
}
.card__relationship {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 8px;
  &__item {
    background: #f0f2f4;
    border-radius: 50px;
    padding: 2px 16px;
    display: flex;
    align-items: center;
    color: #17445c;
    font-size: 15px;
    line-height: 21px;
    box-shadow: 2px 2px 4px #ffffff, inset 2px 2px 4px rgba(0, 0, 0, 0.15);
    @include mobile {
      font-size: 14px;
      line-height: 20px;
    }
    &__pic {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
    }
  }
}
.card__link {
  position: absolute;
  top: 20px;
  right: 24px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background: #f0f2f4;
  box-shadow: -1px -1px 4px #ffffff, -1px -1px 4px #ffffff,
    2px 2px 6px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  padding: 4.5px;
  @include mobile {
    top: 12px;
    right: 16px;
    width: 28px;
    height: 28px;
    padding: 3.5px;
  }
  img {
    width: 100%;
    height: 100%;
  }
}
</style>
